import React from "react";
import { LayoutHead } from "@/layouts/LayoutHead";
import Link from "next/link";
import Image from "next/image";
export function ErrorLayout({
  children,
  title,
  errorCode
}: {
  children?: React.ReactNode;
  title?: string;
  errorCode?: number;
}) {
  return <>
        <LayoutHead title={title} data-sentry-element="LayoutHead" data-sentry-source-file="error-layout.tsx" />
        <div>
            <div className="d-flex flex-column justify-content-center align-items-center dvh-100 overflow-y-auto login-image-panel">
                <div className="container-xxl">

                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 text-center h-100 order-lg-2">
                            {errorCode == 404 ? <Image className="fill-white" src="/svg/illustrations/ill-1.svg" alt="..." width={536} height={404} /> : <Image className="fill-white" src="/svg/illustrations/ill-7.svg" alt="..." width={536} height={368} />}

                        </div>
                        <div className="col-lg-4 order-lg-1">
                            <div className="">
                                <h1 className="display-1 text-dark">Ooops!</h1>

                                <p className="lead lh-relaxed text-dark">
                                    {errorCode == 404 ? <>The page you are looking for could not be found.</> : <>Sorry,
                                        something went wrong.</>}</p>
                                <div className="my-10">
                                    <Link href="/" className="btn btn-dark" data-sentry-element="Link" data-sentry-source-file="error-layout.tsx">Return Home</Link>
                                </div>
                                <div className="vstack gap-5 w-lg-88">
                                    <div className="">
                                        <Link href="/" className="h5 text-dark text-primary-hover text-dark" data-sentry-element="Link" data-sentry-source-file="error-layout.tsx">
                                            Get our support<i className="far fa-arrow-right ms-2"></i>
                                        </Link>
                                        <p className="text-sm text-dark">Check out our communication channels.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>;
}