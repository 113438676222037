import Head from "next/head";
import React from "react";
export function LayoutHead({
  children,
  title,
  description,
  noPrefix = false
}: {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  noPrefix?: boolean;
}): React.ReactElement {
  const siteTitle: string = 'Braiv';
  let pageTitle = siteTitle + (title ? ` - ${title}` : '');
  if (noPrefix) {
    pageTitle = title || pageTitle;
  }
  return <>
        <Head data-sentry-element="Head" data-sentry-source-file="LayoutHead.tsx">
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <title>{pageTitle}</title>
            {description && <meta name="description" content={description} />}
            <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="LayoutHead.tsx" />
            <meta name="color-scheme" content="dark light" data-sentry-element="meta" data-sentry-source-file="LayoutHead.tsx" />
            <meta charSet="UTF-8" data-sentry-element="meta" data-sentry-source-file="LayoutHead.tsx" />
            {children}
        </Head>
    </>;
}